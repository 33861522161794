import { Bars2Icon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'

import { Logo } from '#components/shared/Logo'
import { MobileSidebar } from '#components/shared/templates/Sidebar/MobileSidebar'
import { TopBarAccountButton } from '#components/shared/TopBar/components/TopBarAccountButton'
import { useUserContext } from '#contexts/User'
import { useLandingRoute } from '#hooks/utils/useLandingRoute'
import { GREETINGS } from '#src/constants/copy'
import { routes } from '#utils/routes'

export function TopBar(): JSX.Element {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const user = useUserContext()
  const landingRoute = useLandingRoute()

  const profileButtonText = user?.account ? 'My Portal' : 'Login'
  const profileButtonHref = user?.account ? landingRoute : routes.login

  return (
    <header className="z-20 flex h-[72px] justify-center border-b border-gray-700 bg-white md:border-b-0">
      <div className="flex h-full w-full max-w-[1072px] flex-row-reverse items-center justify-between px-4 py-4 md:flex-row md:px-6">
        <Logo />

        <TopBarAccountButton
          href={profileButtonHref}
          className="hidden rounded-full pl-3 md:block"
        >
          <TopBarAccountButton.Greeting
            className="text-right"
            name={user?.account}
            greeting={GREETINGS.hello}
            linkText={profileButtonText}
          />

          <TopBarAccountButton.Icon />
        </TopBarAccountButton>
        <button
          type="button"
          className="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <MobileSidebar open={sidebarOpen} setOpen={setSidebarOpen} />
    </header>
  )
}
