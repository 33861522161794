import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { LoadingScreen } from '#components/shared/LoadingScreen'
import { DisplayAppNotifications } from '#components/shared/Notifications'
import { useAppRedirection, useAuthStatus } from '#contexts/Authentication'
import { useUserContext } from '#contexts/User'
import { PageOptions } from '#types/next'
import { routes } from '#utils/routes'

type AppAuthCheckerProps = {
  options?: PageOptions
  children: React.ReactElement
}

/**
 * @description Handle application after providers are loaded (as hook data is defined in providers)
 */
export function AppAuthChecker({
  options = {},
  children,
}: AppAuthCheckerProps) {
  const router = useRouter()
  const authState = useAuthStatus()
  const user = useUserContext()

  const shouldRedirectToLogin = !options.public && authState === 'INVALID'
  const isWaitingAuthProvider = !options.public && authState === 'NOT_SET'
  const isWaitingUserProvider = !options.public && user === null

  const { onAppMount } = useAppRedirection(shouldRedirectToLogin)

  useEffect(() => onAppMount(), [onAppMount])

  const isVerifiedUser =
    user?.isPhoneVerified === true && user?.isEmailVerified === true

  const shouldRedirectToActivateAccount =
    !options.public &&
    !isWaitingUserProvider &&
    !isVerifiedUser &&
    !router.asPath.includes('activate-account')

  useEffect(() => {
    if (shouldRedirectToActivateAccount) {
      router.push(routes.activateAccount)
    }
  }, [shouldRedirectToActivateAccount, router])

  const isAppLoading: boolean =
    isWaitingAuthProvider ||
    shouldRedirectToLogin ||
    isWaitingUserProvider ||
    shouldRedirectToActivateAccount

  if (isAppLoading) return <LoadingScreen />

  const pageNode = options.getLayout?.(children) || children

  return (
    <>
      <DisplayAppNotifications />

      {pageNode}
    </>
  )
}
