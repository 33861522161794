import { routes } from '../../utils/routes'
import { useRolePermissions } from './useRolePermissions'

export const useLandingRoute = () => {
  const { isWarehouseUser } = useRolePermissions()

  if (isWarehouseUser) {
    return routes.incomingPackages()
  }

  return routes.dashboard
}
