import {
  LDProvider,
  type ProviderConfig,
  useLDClient,
} from 'launchdarkly-react-client-sdk'
import { ReactNode, useEffect } from 'react'

import { env } from '#src/env.mjs'

import { useUserContext } from './User'

const LAUNCHDARKLY_SDK_CLIENT_SIDE_ID =
  env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID

/*
   We need to override the identification because the first render the authentication is anonymous.
*/
const ContextManager = ({ children }: { children: ReactNode }) => {
  const ldClient = useLDClient()
  const user = useUserContext()

  useEffect(() => {
    if (!user?.qid || !user?.email) return

    ldClient?.identify({
      kind: 'user',
      key: user.qid,
      email: user.email,
      country: user.country,
    })
  }, [user?.qid, ldClient, user?.email, user?.country])

  return children
}

export function LaunchDarklyProvider({
  children,
}: { children: ReactNode } & ProviderConfig['options']) {
  const user = useUserContext()

  if (!LAUNCHDARKLY_SDK_CLIENT_SIDE_ID) {
    return children
  }

  return (
    <LDProvider
      clientSideID={LAUNCHDARKLY_SDK_CLIENT_SIDE_ID}
      context={{
        kind: 'user',
        key: user ? user.qid : 'anonymous', // ensure that logged out users are treated as anonymous. This save us cost on LD.
        email: user?.email,
        country: user?.country,
      }}
    >
      <ContextManager>{children}</ContextManager>
    </LDProvider>
  )
}
