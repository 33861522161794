import { SVGProps } from 'react'

type Path = SVGProps<SVGPathElement>

type IconProps = {
  title?: string
} & Pick<SVGProps<SVGElement>, 'className' | 'viewBox' | 'aria-hidden'>

type IconBaseProps = {
  path: Path
} & IconProps

type Icon = {
  (props: IconProps): JSX.Element
  displayName: string
}

const IconBase = ({
  title,
  path,
  className,
  viewBox,
  'aria-hidden': ariaHidden,
}: IconBaseProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox={viewBox}
    width="24px"
    fill="#FFFFFF"
    role="img"
    aria-hidden={ariaHidden}
  >
    {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
    <>
      {title ? <title>{title}</title> : null}
      {path}
    </>
  </svg>
)

export const createIcon = (
  path: Path,
  displayName: string,
  viewBox = '0 0 24 24',
): Icon => {
  const Icon = ({ title, className, 'aria-hidden': ariaHidden }: IconProps) => (
    <IconBase
      title={title}
      path={path}
      className={className}
      viewBox={viewBox}
      aria-hidden={ariaHidden}
    />
  )
  Icon.displayName = displayName
  return Icon
}
