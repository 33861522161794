import type { ValidParamsOptions } from '#src/types/api'

/**
 * @param options A request options object.
 * @returns A URLSearchParams instance.
 */
export function getPreparedParams<T extends ValidParamsOptions>(options: T) {
  const params = new URLSearchParams()

  for (const key in options) {
    const value = options[key]

    // Ensure value is available before continuing.
    if (value === undefined || value === null) continue

    if (typeof value === 'string') params.set(key, value)
    if (typeof value === 'number') params.set(key, value.toString())
    if (typeof value === 'boolean') params.set(key, value ? 'true' : 'false')

    if (Array.isArray(value)) {
      for (const item of value) {
        params.append(key, String(item).toString())
      }
    }
  }

  return params
}
