import clsx from 'clsx'
import React from 'react'

import { TopBarAccountButton } from '#components/shared/TopBar/components/TopBarAccountButton'
import { GREETINGS } from '#src/constants/copy'
import { routes } from '#utils/routes'

interface SidebarMyProfileButtonProps {
  companyName?: string
  onClickRoute?: () => void
}

export function SidebarMyProfileButton({
  companyName,
  onClickRoute,
}: SidebarMyProfileButtonProps) {
  const isLoggedIn = !!companyName

  return (
    <div
      className={clsx(
        'flex h-20 w-full grow-0 flex-row items-center gap-1 border-t-[1px] border-gray-200 px-6 py-1 text-gray-900 outline-none',
        {
          'border-b-[1px]': isLoggedIn,
        },
      )}
    >
      <TopBarAccountButton
        className={clsx('w-full')}
        onClick={onClickRoute}
        href={routes.myProfile()}
      >
        <div className="text-gray-400">
          <TopBarAccountButton.Icon />
        </div>
        <TopBarAccountButton.Greeting
          name={companyName}
          greeting={GREETINGS.hello}
          linkText={isLoggedIn ? 'My profile' : 'Login'}
        />
      </TopBarAccountButton>
    </div>
  )
}
