import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import { identifyPage } from '#utils/report/tracking'

export function useAppAnalytics(): void {
  const { events, pathname, query } = useRouter()

  const hasIdentifiedFirstPageRef = useRef(false)

  const queryRef = useRef(query)
  queryRef.current = query

  // identify initial page
  useEffect(() => {
    if (hasIdentifiedFirstPageRef.current) return
    identifyPage(pathname, queryRef.current)
    hasIdentifiedFirstPageRef.current = true
  }, [pathname])

  useEffect(() => {
    const handleRouteChange = () => {
      identifyPage(pathname, queryRef.current)
    }

    events.on('routeChangeComplete', handleRouteChange)

    return () => events.off('routeChangeComplete', handleRouteChange)
  }, [events, pathname])
}
