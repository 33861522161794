export class InvalidWiseCurrency extends Error {}

export class FieldError extends Error {
  fieldErrors: string[]
  statusCode: number
  constructor(
    message: string | undefined,
    statusCode: number,
    fieldErrors: string[],
  ) {
    super(message)
    this.fieldErrors = fieldErrors
    this.statusCode = statusCode
  }
}

export const validateFieldError = (error: unknown) => {
  if (error instanceof FieldError) {
    return error as FieldError
  }
  return false
}

export class InvalidPortalUserLoginError extends Error {
  constructor() {
    super()
    this.name = 'InvalidPortalUserLoginError'
  }
}

export class FailedToFetchError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'FailedToFetchError'
  }
}
