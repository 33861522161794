import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import { cva, type VariantProps } from 'class-variance-authority'
import { createContext, ReactNode, useContext } from 'react'
import { SetNonNullable, type SetRequired } from 'type-fest'

import { cn } from '../utils'

const alertBannerVariants = cva('shadow-[0_4px_4px_0_rgba(0,0,0,0.05)]', {
  variants: {
    variant: {
      neutral: 'bg-gray-100 text-gray-900',
      error: 'border-error-700 bg-error-50 text-error-900',
    },
  },
})

// By default CVA makes props nullable and optional, but we want to require the variant is passed
type AlertBannerVariantProps = SetNonNullable<
  SetRequired<VariantProps<typeof alertBannerVariants>, 'variant'>,
  'variant'
>

type AlertBannerContext = {
  onClose?: () => void
} & AlertBannerVariantProps

const AlertBannerContext = createContext<AlertBannerContext | null>(null)

const useAlertBannerContext = () => {
  const context = useContext(AlertBannerContext)
  if (!context)
    throw new Error(
      '[Qogita UI] Alert banner components must be used inside an AlertBanner',
    )
  return context
}

type AlertBannerProps = Pick<AlertBannerContext, 'onClose' | 'variant'> & {
  children: ReactNode
  className?: string
}

export function AlertBannerRoot({
  onClose,
  variant,
  children,
  className,
}: AlertBannerProps) {
  return (
    <AlertBannerContext.Provider value={{ onClose, variant }}>
      <div className={cn(alertBannerVariants({ variant }), className)}>
        {children}
      </div>
    </AlertBannerContext.Provider>
  )
}

type AlertBannerInnerProps = {
  children: ReactNode
  className?: string
}
function AlertBannerInner({ children, className }: AlertBannerInnerProps) {
  return (
    <div
      className={cn(
        'mx-auto flex items-start gap-4 px-4 py-4 md:gap-6 md:px-6',
        className,
      )}
    >
      {children}
    </div>
  )
}

const iconVariants = {
  neutral: ExclamationCircleIcon,
  error: ExclamationTriangleIcon,
} as const

const alertBannerIconVariants = cva('h-6 w-6 flex-none', {
  variants: {
    variant: {
      neutral: 'text-gray-400',
      error: 'text-error-700',
    },
  },
})

function AlertBannerIcon() {
  const { variant } = useAlertBannerContext()
  const Icon = iconVariants[variant]

  return <Icon className={alertBannerIconVariants({ variant })} />
}

type AlertBannerContentProps = {
  children: ReactNode
}
function AlertBannerContent({ children }: AlertBannerContentProps) {
  return (
    <div className="flex grow flex-col md:flex-row md:flex-wrap md:gap-2">
      {children}
    </div>
  )
}

type AlertBannerTitleProps = {
  children: ReactNode
}
function AlertBannerTitle({ children }: AlertBannerTitleProps) {
  return <h2 className="q-text-body-base-bold">{children}</h2>
}

type AlertBannerBodyProps = {
  children: ReactNode
}
function AlertBannerBody({ children }: AlertBannerBodyProps) {
  return <p className="q-text-body-base">{children}</p>
}

const alertBannerCloseButtonVariants = cva('h-5 w-5', {
  variants: {
    variant: {
      neutral: 'text-gray-400',
      error: 'text-error-700',
    },
  },
})

function AlertBannerCloseButton() {
  const { onClose, variant } = useAlertBannerContext()

  return (
    <button
      type="button"
      onClick={onClose}
      className="grid h-6 w-6 place-items-center"
    >
      <XMarkIcon
        className={alertBannerCloseButtonVariants({ variant })}
        title="close"
      />
    </button>
  )
}

export const AlertBanner = Object.assign(AlertBannerRoot, {
  Inner: AlertBannerInner,
  Icon: AlertBannerIcon,
  Content: AlertBannerContent,
  Title: AlertBannerTitle,
  Body: AlertBannerBody,
  CloseButton: AlertBannerCloseButton,
})
