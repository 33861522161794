import clsx from 'clsx'
import { useRouter } from 'next/router'

import { LogisticsSidebar } from '#components/shared/templates/Sidebar/LogisticsSidebar'
import { SellerSidebar } from '#components/shared/templates/Sidebar/SellerSidebar'
import { SidebarLogoutButton } from '#components/shared/templates/Sidebar/SidebarLogoutButton'
import { SidebarMyProfileButton } from '#components/shared/templates/Sidebar/SidebarMyProfileButton'
import { useUserContext } from '#contexts/User'
import { useRolePermissions } from '#hooks/utils/useRolePermissions'

type SidebarProps = {
  className?: string
  onClickRoute?: () => void
}

export function Sidebar({ className, onClickRoute }: SidebarProps) {
  const router = useRouter()
  const { isWarehouseUser } = useRolePermissions()
  const user = useUserContext()

  const getIsActive = (testPath: RegExp) => {
    if (!router.isReady) return false

    return testPath.test(router.asPath)
  }

  return (
    <div
      className={clsx(
        'flex h-full flex-col justify-between overflow-y-auto',
        className,
      )}
    >
      <nav className="h-full flex-1 flex-col justify-between space-y-1 overflow-y-auto pt-6 md:pt-0">
        {isWarehouseUser ? (
          <LogisticsSidebar
            validateIsActiveLink={getIsActive}
            onClickRoute={onClickRoute}
          />
        ) : (
          <SellerSidebar
            validateIsActiveLink={getIsActive}
            onClickRoute={onClickRoute}
          />
        )}
      </nav>

      <SidebarMyProfileButton
        companyName={user?.company}
        onClickRoute={onClickRoute}
      />

      {user ? <SidebarLogoutButton /> : null}
    </div>
  )
}
