/**
 * Payment
 */
export { AmexIcon } from './payment/Amex'
export { ApplePayIcon } from './payment/ApplePay'
export { GooglePayIcon } from './payment/GooglePay'
export { IdealIcon } from './payment/Ideal'
export { MasterCardIcon } from './payment/MasterCard'
export { UnionPayIcon } from './payment/UnionPay'
export { VisaIcon } from './payment/Visa'

/**
 * Social media
 */
export { InstagramIcon } from './socialMedia/Instagram'
export { LinkedinIcon } from './socialMedia/Linkedin'
export { TwitterIcon } from './socialMedia/Twitter'
