import { logError } from '@qogita/logging'
import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

/*
  The Suspense will catch server side errors and allow us to recover from server side errors and
  then during client side rendering, the ErrorBoundary will catch any client side errors and log them.
  This ensures that any panel that fails won't break the entire page.
 */
export const CmsErrorBoundary = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <Suspense fallback={null}>
      <ErrorBoundary
        FallbackComponent={() => null}
        onError={(error) => {
          logError(error)
        }}
      >
        {children}
      </ErrorBoundary>
    </Suspense>
  )
}
