import { env } from '#src/env.mjs'

/**
 * @description Ensure required env vars are set
 */
const getConfig = () => {
  const BASE_URL = env.NEXT_PUBLIC_API_BASE_URL

  if (!BASE_URL) {
    throw new Error('You must set "NEXT_PUBLIC_API_BASE_URL" to run this app')
  }

  return { BASE_URL } as const
}

export const isProduction = env.NEXT_PUBLIC_ENV === 'production'

export const IS_LOGISTICS_ENABLED = env.NEXT_PUBLIC_ENABLE_LOGISTICS

const { BASE_URL } = getConfig()

export { BASE_URL }
