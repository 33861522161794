import clsx from 'clsx'

type InternalMessageProps = {
  id: string
  className?: string
  children: string
}

/**
 *
 * @deprecated
 */
export function DeprecatedErrorMessage({
  className,
  ...props
}: InternalMessageProps) {
  return (
    <p
      {...props}
      className={clsx(className, 'text-error-700 q-text-body-sm mt-1 flex')}
    />
  )
}
