export const ONE_SECOND_MS = 1000
export const TWO_SECONDS = ONE_SECOND_MS * 2
export const FIVE_SECONDS = ONE_SECOND_MS * 5
export const TEN_SECONDS = ONE_SECOND_MS * 10
export const FIFTEEN_SECONDS = ONE_SECOND_MS * 15
export const THIRTY_SECONDS = ONE_SECOND_MS * 30

export const ONE_MINUTE_MS = ONE_SECOND_MS * 60
export const TWO_MINUTES_MS = ONE_MINUTE_MS * 2
export const THREE_MINUTES_MS = ONE_MINUTE_MS * 3
export const FOUR_MINUTES_MS = ONE_MINUTE_MS * 4
export const FIVE_MINUTES_MS = ONE_MINUTE_MS * 5
export const TEN_MINUTES_MS = ONE_MINUTE_MS * 10
export const FIFTEEN_MINUTES_MS = ONE_MINUTE_MS * 15

export const ONE_HOUR_MS = ONE_MINUTE_MS * 60

export const ONE_DAY_MS = ONE_HOUR_MS * 24
export const TWO_DAY_MS = ONE_DAY_MS * 2
export const FIVE_DAYS_MS = 5 * ONE_DAY_MS

export const TIME_OFFSET = {
  within10seconds: 'A few moments',
  within30seconds: 'Within 30 seconds',
  within30to60seconds: 'Within a minute',
  after1minute: 'A minute',
  after2minutes: 'A few minutes',
  after3minutes: 'A while',
}
