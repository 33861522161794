type GetFormattedDateTime = (
  value: number | string,
  locale?: string,
  options?: Intl.DateTimeFormatOptions,
) => string

/**
 * Format a unix timestamp to a date time string.
 * @param value A date-time number/string object value.
 * @param locale An optional locale. Default: 'en-GB'.
 * @param options An optional configuration option for
 * the formating style. Default: { dateStyle: 'long' }.
 * @returns A date time string for a given unix timestamp.
 */
export const getFormattedDateTime: GetFormattedDateTime = (
  value,
  locale = 'en-GB',
  // eslint-disable-next-line unicorn/no-object-as-default-parameter
  options = { dateStyle: 'long' },
) => {
  const date = new Date(value)
  const formattedDate = new Intl.DateTimeFormat(locale, options).format(date)
  return formattedDate
}
