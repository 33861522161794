import clsx from 'clsx'
import { type FocusEvent, forwardRef, useState } from 'react'

import type { CountryDescriptor } from '../../Utils/country.helpers'
import { DeprecatedCombobox } from '../DeprecatedCombobox'
import {
  DeprecatedField,
  DeprecatedFormFieldInputParam as FormFieldInputParameter,
  DeprecatedFormFieldInternalParam as FormFieldInternalParameter,
  useDeprecatedFormField,
} from '.'
import { DeprecatedFormInput } from './DeprecatedControls'
import { DeprecatedErrorMessage } from './DeprecatedErrorMessage'
import { DeprecatedFormLabel } from './DeprecatedFieldLabel'
/**
 * @deprecated
 */
const PhoneFieldRoot = forwardRef<HTMLInputElement, FormFieldInputParameter>(
  function PhoneField(phoneFieldProps, phoneFieldRef) {
    const {
      className,
      id,
      name,
      label,
      description,
      errorMessage,
      type = 'text',
      required = false,
      disabled = false,
      children,
      tooltip,
      ...props
    } = phoneFieldProps

    const { fieldProps, labelProps, controlProps, fieldErrorProps } =
      useDeprecatedFormField({
        id,
        name,
        label,
        description,
        errorMessage,
        required,
        disabled,
        tooltip,
        fieldset: false,
      })

    return (
      <DeprecatedField {...fieldProps} className={className}>
        {'htmlFor' in labelProps && <DeprecatedFormLabel {...labelProps} />}

        <div className="flex flex-wrap">
          {children}

          {controlProps && (
            <div className="order-2 grow">
              <DeprecatedFormInput
                {...props}
                {...controlProps}
                ref={phoneFieldRef}
                type={type}
                className="rounded"
              />
            </div>
          )}
        </div>

        {fieldErrorProps && <DeprecatedErrorMessage {...fieldErrorProps} />}
      </DeprecatedField>
    )
  },
)

type CallingCodeProps = Omit<
  FormFieldInputParameter,
  'onChange' | 'value' | 'label'
> & {
  id?: string
  className?: string
  label?: string
  name: string
  required?: boolean
  disabled?: boolean
  value?: string
  onChange?: (value: string) => void
  errorMessage?: FormFieldInternalParameter['errorMessage']
  countries: CountryDescriptor[]
}

const getCountryNameWithCallingCode = (country: CountryDescriptor) =>
  `${country.name} ${country.callingCode}`
const getCallingCodeWithPlusPrefix = (callingCode: string) =>
  callingCode.startsWith('+') ? callingCode : `+${callingCode}`
const IS_CALLING_CODE_ONLY = /^[+]?[0-9]+$/
/**
 * @deprecated
 */
function DeprecatedCallingCodeField({
  className,
  label = 'Calling Code',
  errorMessage,
  required = false,
  disabled = false,
  onChange,
  value,
  countries,
  onBlur,
  onFocus,
  ...props
}: CallingCodeProps): JSX.Element {
  const [query, setQuery] = useState('')

  let filteredCallingCodeList = countries.filter((country) =>
    getCountryNameWithCallingCode(country)
      .toLowerCase()
      .includes(query.toLowerCase()),
  )
  /**
   * Pins the exact match to the top of the list
   * @param countryA
   * @param countryB
   */
  const pinExactMatchToTop = (
    countryA: CountryDescriptor,
    countryB: CountryDescriptor,
  ) => {
    if (countryA.callingCode === getCallingCodeWithPlusPrefix(query)) return -1
    if (countryB.callingCode === getCallingCodeWithPlusPrefix(query)) return 1
    return 0
  }

  if (IS_CALLING_CODE_ONLY.test(query)) {
    filteredCallingCodeList = filteredCallingCodeList.sort(pinExactMatchToTop)
  }

  const getCountryDescriptorForDialingCode = (callingCode?: string) =>
    countries.find((country) => country.callingCode === callingCode) ??
    filteredCallingCodeList[0]

  const onComboboxChange = (newValue: CountryDescriptor | null) => {
    newValue?.callingCode && onChange?.(newValue?.callingCode)
    setOpenOptions(false)
  }

  const { fieldProps, controlProps, fieldErrorProps, labelProps } =
    useDeprecatedFormField({
      label,
      errorMessage,
      required,
      disabled,
      fieldset: false,
      ...props,
    })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { as, ...restFieldProps } = fieldProps
  const { error, ...restControlProps } = controlProps ?? { error: false }

  const [openOptions, setOpenOptions] = useState<boolean>(false)

  const onComboboxInputFocused = (
    event: FocusEvent<HTMLInputElement, Element>,
  ) => {
    onFocus?.(event)
    event.target.setSelectionRange(0, event.target.value.length)
    setOpenOptions(true)
  }

  const onComboboxInputBlurred = (
    event: FocusEvent<HTMLInputElement, Element>,
  ) => {
    onBlur?.(event)
    if (!event.relatedTarget?.id.includes('headlessui-combobox-option')) {
      setOpenOptions(false)
    }
  }

  return (
    <>
      <DeprecatedCombobox<CountryDescriptor>
        value={getCountryDescriptorForDialingCode(value)}
        onChange={onComboboxChange}
      >
        {'htmlFor' in labelProps && (
          <DeprecatedCombobox.Label className="sr-only" {...labelProps} />
        )}
        {controlProps && (
          <div className={clsx(className, 'relative w-28')}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore TODO: fix this type error */}
            <DeprecatedCombobox.Input<CountryDescriptor, 'input'>
              displayValue={(country) => country.callingCode}
              onChange={(event) => setQuery(event.target.value)}
              onFocus={onComboboxInputFocused}
              onBlur={onComboboxInputBlurred}
              className={clsx({
                'border-error-700 focus:border-error-700 focus:ring-error-700':
                  error,
              })}
              {...restFieldProps}
              {...restControlProps}
              {...props}
            />
            <DeprecatedCombobox.Button />
            <DeprecatedCombobox.Options className="w-fit" static={openOptions}>
              {(filteredCallingCodeList.length
                ? filteredCallingCodeList
                : countries
              ).map((country) => (
                <DeprecatedCombobox.Option
                  className="flex items-center"
                  value={country}
                  key={getCountryNameWithCallingCode(country)}
                >
                  <span className="max-w-[13rem] truncate">{country.name}</span>
                  <span className="ui-active:text-white whitespace-nowrap pl-2 text-gray-400">
                    {country.callingCode}
                  </span>
                </DeprecatedCombobox.Option>
              ))}
            </DeprecatedCombobox.Options>
          </div>
        )}
      </DeprecatedCombobox>
      {fieldErrorProps && (
        <DeprecatedErrorMessage
          className="order-3 basis-full"
          {...fieldErrorProps}
        />
      )}
    </>
  )
}

/**
 * @deprecated
 */
export const DeprecatedPhoneField = Object.assign(PhoneFieldRoot, {
  Prefix: DeprecatedCallingCodeField,
})
