import { ReactNode } from 'react'

import { Footer } from '#components/shared/Footer'
import { TopBar } from '#components/shared/TopBar'
import { UserProvider } from '#contexts/User'
import { PageStoryblok } from '#src/types/storyblok-component-types'

type PublicLayoutProps = {
  children: ReactNode
  pageBlok: PageStoryblok | null
}

export const PublicLayout = ({ children, pageBlok }: PublicLayoutProps) => {
  return (
    <UserProvider>
      <div className="flex min-h-screen flex-col">
        <TopBar />
        <main className="flex grow flex-col">{children}</main>
        <Footer pageBlok={pageBlok} />
      </div>
    </UserProvider>
  )
}
