import { CanaryClient, useCanaryClient } from '@qogita/canary-client'
import {
  IbanBankCreateRequest,
  PatchedCurrentUserRequest,
  SellerFeeRequest,
  SortCodeBankCreateRequest,
} from '@qogita/canary-types'
import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { getPrivateQueryKey } from '#utils/queryKey'
import { trackProfilePaymentDetailsUpdated } from '#utils/report/tracking'

export function getUserQueries(canaryClient: CanaryClient) {
  const queries = {
    all: () => [...getPrivateQueryKey(), 'user'] as const,
    detail: () =>
      queryOptions({
        queryKey: queries.all(),
        queryFn: () => {
          return canaryClient.getUser()
        },
      }),
    bankInfo: () =>
      queryOptions({
        queryKey: [...queries.all(), 'bankInfo'],
        queryFn: () => canaryClient.getUserBankInfo(),
      }),
  }

  return queries
}

export const useUpdateUserFee = () => {
  const queryClient = useQueryClient()
  const canaryClient = useCanaryClient()

  return useMutation({
    mutationFn: (data: SellerFeeRequest) => canaryClient.updateUserFee(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries()
    },
  })
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient()
  const canaryClient = useCanaryClient()
  const userQueries = getUserQueries(canaryClient)

  return useMutation({
    mutationFn: (data: PatchedCurrentUserRequest) =>
      canaryClient.updateUser(data),
    onSuccess: (updatedUser) => {
      queryClient.setQueryData(userQueries.detail().queryKey, updatedUser)
      queryClient.invalidateQueries()
    },
  })
}

export function useCreateUserBankIbanMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: IbanBankCreateRequest) =>
      canaryClient.createUserBankIban(data),
    onSuccess: async () => {
      trackProfilePaymentDetailsUpdated()
      await queryClient.invalidateQueries()
    },
  })
}

export function useCreateUserBankSortCodeMutation() {
  const canaryClient = useCanaryClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: SortCodeBankCreateRequest) =>
      canaryClient.createUserBankSortCode(data),
    onSuccess: async () => {
      trackProfilePaymentDetailsUpdated()
      await queryClient.invalidateQueries()
    },
  })
}
