import { IS_LOGISTICS_ENABLED } from '#src/constants/featureFlags'
import { useUserContext } from '#src/contexts/User'

export function useRolePermissions() {
  const user = useUserContext()
  const isWarehouseUser = IS_LOGISTICS_ENABLED && !!user?.isWarehouseUser

  return {
    type: isWarehouseUser ? 'WAREHOUSE' : 'SELLER',
    isWarehouseUser,
    isLoggedIn: !!user,
  } as const
}
