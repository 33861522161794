import { createIcon } from '../createIcon'

export const GooglePayIcon = createIcon(
  <>
    <g clipPath="url(#google-pay-clip-path)">
      <path d="M9.811 18.333a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" fill="#fff" />
      <path
        d="M9.81 18.333a8.96 8.96 0 0 1-4.734-1.344L11.106.425A8.999 8.999 0 0 1 9.81 18.333Z"
        fill="#6E7BF2"
      />
      <path
        d="M6.758 9.294a1.908 1.908 0 0 0-.046-.45H4.64v.818h1.216c-.046.29-.21.548-.451.715l-.004.027.654.508.046.004c.416-.384.656-.95.656-1.622Z"
        fill="#4285F4"
      />
      <path
        d="M4.64 11.45c.596 0 1.096-.196 1.462-.534l-.696-.54a1.304 1.304 0 0 1-.765.221 1.33 1.33 0 0 1-1.255-.917l-.026.003-.68.526-.01.024a2.2 2.2 0 0 0 1.97 1.217Z"
        fill="#34A853"
      />
      <path
        d="M3.385 9.68a1.35 1.35 0 0 1-.001-.873l-.001-.028-.69-.536-.023.01a2.201 2.201 0 0 0 0 1.981l.715-.553Z"
        fill="#FBBC05"
      />
      <path
        d="M4.64 7.892c.317-.005.622.112.853.329l.623-.608c-.4-.375-.929-.58-1.476-.574-.835 0-1.597.47-1.971 1.217l.713.553c.18-.544.686-.913 1.259-.917Z"
        fill="#EB4335"
      />
      <path
        d="M10.23 9.49v1.603h-.512v-3.96h1.359c.327-.007.644.116.88.342a1.14 1.14 0 0 1 0 1.677 1.231 1.231 0 0 1-.88.337h-.847Zm0-1.87v1.383h.859a.674.674 0 0 0 .506-.205.67.67 0 0 0 .014-.955l-.014-.013a.67.67 0 0 0-.506-.21h-.859Zm3.275.675c.379 0 .678.1.897.301.219.2.328.476.328.827v1.67h-.49v-.377h-.021c-.212.31-.495.465-.848.465a1.101 1.101 0 0 1-.754-.266.846.846 0 0 1-.303-.663.803.803 0 0 1 .32-.669c.213-.165.498-.248.855-.248.26-.01.52.047.751.165v-.116a.576.576 0 0 0-.211-.45.727.727 0 0 0-.495-.185.788.788 0 0 0-.679.36l-.45-.283c.245-.354.612-.531 1.1-.531Zm-.664 1.968c0 .132.063.256.17.332a.626.626 0 0 0 .398.133.816.816 0 0 0 .576-.238.742.742 0 0 0 .255-.559c-.16-.126-.383-.19-.67-.19a.869.869 0 0 0-.52.15.451.451 0 0 0-.209.372Zm4.703-1.88-1.71 3.904h-.53l.635-1.366-1.126-2.538h.557l.814 1.947h.01l.792-1.947h.558Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="google-pay-clip-path">
        <path
          fill="#fff"
          transform="translate(.811 .333)"
          d="M0 0h18.01v18H0z"
        />
      </clipPath>
    </defs>
  </>,
  'GooglePayIcon',
  '0 0 19 19',
)
