// @ts-check
// This is a .mjs file, so that it can be imported into
// environment.mjs, which can be imported into next.config.mjs to get build
// time environment variable validation.
import { createEnv } from '@t3-oss/env-nextjs'
import { z } from 'zod'
import {
  booleanEnvironmentSchema,
  urlOrIPSchema,
} from '#utils/environment.utils'

const trimmedString = () => z.string().trim()

/**
 * client-side variable object with zod schema
 */
const client = {
  NEXT_PUBLIC_BASE_URL: trimmedString().url(),
  NEXT_PUBLIC_API_BASE_URL: trimmedString().url(),

  NEXT_PUBLIC_ENV: z.enum(['development', 'preview', 'production']).optional(),

  // Segment
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: trimmedString(),

  // Datadog RUM
  NEXT_PUBLIC_DATADOG_RUM_ENABLED: booleanEnvironmentSchema,
  NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID: trimmedString(),
  NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN: trimmedString(),
  NEXT_PUBLIC_DATADOG_CSP_REPORT_TOKEN: trimmedString(),
  NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID: trimmedString().optional(),

  // Vercel mirroring
  NEXT_PUBLIC_VERCEL_BRANCH_URL: trimmedString(),
  NEXT_PUBLIC_VERCEL_ENV: trimmedString(),

  // Feature flags
  NEXT_PUBLIC_ENABLE_LOGISTICS: booleanEnvironmentSchema,
  NEXT_PUBLIC_ENABLE_TAKE_OVER_WAREHOUSE_SHIPMENTS: booleanEnvironmentSchema,

  // Storyblok
  NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN: trimmedString(),
}

/**
 * server-side variable object with zod schema
 */
const server = {
  NODE_ENV: z.enum(['development', 'test', 'preview', 'production']),

  CI: booleanEnvironmentSchema,

  // Vercel
  VERCEL_ENV: z.enum(['development', 'preview', 'production']),
  VERCEL_GIT_COMMIT_SHA: z.string(),

  // Playwright
  PLAYWRIGHT_API_BASE_URL: urlOrIPSchema,
  E2E_BASE_URL: urlOrIPSchema.optional(),

  // Private feature flags
  NEXT_FEATURE_ENABLE_PROFORMA_UPLOAD: booleanEnvironmentSchema,
}

/**
 * Environment object with manual declaration of runtime variables
 * (can't be destructured on the fly because of the way Next.js works)
 */
// Ignoring Prettier to not wrap the code to multiple lines so it's easier to read
// prettier-ignore
export const env = createEnv({
  client,
  server,
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    E2E_BASE_URL: process.env.E2E_BASE_URL,
    CI: process.env.CI,
    NEXT_PUBLIC_API_BASE_URL: process.env.NEXT_PUBLIC_API_BASE_URL,
    VERCEL_ENV: process.env.VERCEL_ENV,
    PLAYWRIGHT_API_BASE_URL: process.env.PLAYWRIGHT_API_BASE_URL,
    NEXT_FEATURE_ENABLE_PROFORMA_UPLOAD: process.env.NEXT_FEATURE_ENABLE_PROFORMA_UPLOAD,
    NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_BASE_URL,
    NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
    NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    NEXT_PUBLIC_DATADOG_CSP_REPORT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_CSP_REPORT_TOKEN,
    NEXT_PUBLIC_DATADOG_RUM_ENABLED: process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED,
    NEXT_PUBLIC_ENABLE_LOGISTICS: process.env.NEXT_PUBLIC_ENABLE_LOGISTICS,
    NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
    NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    VERCEL_GIT_COMMIT_SHA: process.env.VERCEL_GIT_COMMIT_SHA,
    NEXT_PUBLIC_ENABLE_TAKE_OVER_WAREHOUSE_SHIPMENTS: process.env.NEXT_PUBLIC_ENABLE_TAKE_OVER_WAREHOUSE_SHIPMENTS,
    NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
    NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
    // Vercel mirroring
    NEXT_PUBLIC_VERCEL_BRANCH_URL: process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL,
    NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  },
});
