import { cn } from '@qogita/ui'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

import { LegalPanelStoryblok } from '#types/storyblok-component-types'

import { RichTextTOC } from './RichText'

export function LegalPanel({ blok }: { blok: LegalPanelStoryblok }) {
  return (
    <div {...storyblokEditable(blok)}>
      <div className="mx-auto box-content max-w-5xl px-4 md:px-6">
        <div
          className={cn(
            'relative grid gap-8 py-12 md:grid-rows-[auto_1fr] md:gap-x-24',
            // Layout under md
            // || header  ||
            // || toc     ||
            // || content ||
            "grid-cols-1 [grid-template-areas:'header'_'toc'_'content']",
            // Layout above md
            // || header  | header  | toc ||
            // || content | content | toc ||
            'md:grid-cols-3 md:[grid-template-areas:"header_header_toc"_"content_content_toc"]',
          )}
        >
          <div className="[grid-area:header]">
            {blok.header.map((headerBlok) => (
              <StoryblokComponent key={headerBlok._uid} blok={headerBlok} />
            ))}
          </div>
          <div className="top-8 [grid-area:toc] md:sticky md:h-screen">
            {blok.content.map((blok) => (
              <RichTextTOC key={blok._uid} blok={blok.content} />
            ))}
          </div>
          {blok.content.map((content) => (
            <div key={content._uid} className="[grid-area:content]">
              <StoryblokComponent blok={content} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
