import clsx from 'clsx'

type TopBarAccountButtonGreetingProps = {
  name?: string
  greeting: string
  linkText: string
  className?: string
}

export const TopBarAccountButtonGreeting = ({
  name,
  greeting,
  linkText,
  className,
}: TopBarAccountButtonGreetingProps) => {
  const greetingText = name ? `${greeting}, ${name}` : greeting

  return (
    <div className={clsx('flex flex-col gap-0.5 overflow-hidden', className)}>
      <p className="q-text-body-xs max-w-[12rem] truncate">{greetingText}</p>

      <p className="q-text-body-xs-bold">{linkText}</p>
    </div>
  )
}
