import clsx from 'clsx'
import Link, { LinkProps } from 'next/link'

type TopBarAccountButtonRootProps = LinkProps & {
  className?: string
  children: React.ReactNode
}
export const TopBarAccountButtonRoot = ({
  children,
  className,
  ...props
}: TopBarAccountButtonRootProps) => {
  return (
    <Link
      className={clsx(
        className,
        'focus:ring-primary-700 rounded-full outline-none hover:bg-gray-100 focus:ring-2 active:shadow-[inset_0_4px_4px_rgba(0,0,0,0.25)]',
      )}
      {...props}
    >
      <div className="flex flex-row items-center gap-1 rounded-full">
        {children}
      </div>
    </Link>
  )
}
