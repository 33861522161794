import { QogitaLogoWordmark, QogitaSellersLogoWordmark } from '@qogita/ui'

import { useRolePermissions } from '#src/hooks/utils/useRolePermissions'

type LogoProps = {
  className?: string
}

export function Logo(props: LogoProps) {
  const { isWarehouseUser } = useRolePermissions()

  const logoProps = {
    theme: 'black',
    className: 'h-[53px] w-[144px]',
    ...props,
  } as const

  if (isWarehouseUser) {
    return <QogitaLogoWordmark {...logoProps} />
  }

  return <QogitaSellersLogoWordmark {...logoProps} />
}
