/**
 * All query keys are arrays generated from simple functions. It makes easy to find
 * any cached data by query key. For example:
 *
 * When fetching data from API we can set the queryKey as:
 * ```
 * getMyListQueryKey(filter1, filter2)
 * ```
 *
 * Then, when we need to find all entries from the cache for that specific queryKey,
 * we can do the following:
 * ```
 * const cachedResultsKey = getMyListQueryKey() // all data
 * const cachedResultsKey = getMyListQueryKey(filter1) // data filtered by filter1
 * const cachedResultsKey = getMyListQueryKey(filter1, filter2) // exact match
 * ```
 */

import { ShipmentOutput } from '@qogita/canary-types'

import {
  SaleDestination,
  SaleNextAction,
  SaleSortBy,
} from './salesQueryParameter'

type SaleKeys = [saleQid?: string]
type TokenStatusKeys = [token?: string]
type ShipmentsKeys = [page: string, searchValue: string | undefined]
type SearchKeys = [search: string]

type ShipmentGroupAllKeys = [
  search_destination?: ShipmentOutput['destinationType'][],
  page?: string,
]

type SalesKeys = [
  page: number,
  status: string,
  hasRead?: boolean,
  expiredFrom?: string,
  order?: SaleSortBy,
  destination?: SaleDestination,
  nextAction?: SaleNextAction,
]
type ClaimSaleProcessingDetailListKeys = [page?: number, status?: string[]]

export const getPrivateQueryKey = () => ['private']
const getPublicQueryKey = () => ['public']

export const getInfoQueryKey = () => [...getPublicQueryKey(), 'info']

export const getSaleDocumentsQueryKey = (...keys: SaleKeys) => [
  ...getPrivateQueryKey(),
  'saleDocuments',
  ...keys,
]

export const getTokenStatusQueryKey = (...keys: TokenStatusKeys) => [
  ...getPublicQueryKey(),
  'token-status',
  ...keys,
]

export const getShipmentsQueryKey = (...keys: ShipmentsKeys) => [
  ...getPrivateQueryKey(),
  'shipments',
  ...keys,
]

export const getShipmentsOrdersQueryKey = (...keys: ShipmentsKeys) => [
  ...getPrivateQueryKey(),
  'shipmentsOrders',
  ...keys,
]

export const getShipmentGroupAllQueryKey = (...keys: ShipmentGroupAllKeys) => [
  ...getPrivateQueryKey(),
  'shipmentGroupAll',
  ...keys,
]

export const getShipmentGroupIdQueryKey = (...keys: [groupId: string]) => [
  ...getPrivateQueryKey(),
  'shipmentGroupAll',
  ...keys,
]

export const getWarehouseCommercialInvoiceQueryKey = (
  ...keys: [qid: string]
) => [...getPrivateQueryKey(), 'warehouseCommercialInvoice', ...keys]

export const getSearchQueryKey = (...keys: SearchKeys) => [
  ...getPrivateQueryKey(),
  'searchOffersAndSales',
  ...keys,
]

export const getOrdersQueryKey = (...keys: SalesKeys) => [
  ...getPrivateQueryKey(),
  'orders',
  ...keys,
]

export const getClaimSaleProcessingDetailListKey = (
  ...keys: ClaimSaleProcessingDetailListKeys
) => [...getPrivateQueryKey(), 'ClaimSaleProcessingDetailList', ...keys]
